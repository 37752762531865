<template>
  <div class="test mx-auto" :class="open ? 'hv-100' : ''">
    <!-- Header -->
    <header class="header" :class="open ? 'header-fixed' : ''">
      <div class="container">
        <div class="row">
          <div class="d-flex align-items-center justify-content-between">
            <!-- Logo -->
            <div class="col">
              <div class="logo">
                <img
                  src="../assets/ou7-img/logo.png"
                  alt="Logo Website"
                  class="logo-img"
                />
              </div>
            </div>

            <!-- Header menu -->
            <ul class="header__menu col-10 col-md-9 mx-xl-0 mx-lg-4">
              <li class="header__menu-list" @click="$router.push('/')">
                {{ $t("home") }}
              </li>
              <span class="header__menu-line"></span>
              <li class="header__menu-list" @click="$router.push('/b-fresh')">
                BE FRESH
              </li>
              <span class="header__menu-line"></span>
              <a href="#product">
                <li class="header__menu-list">{{ $t("product") }}</li>
              </a>
              <span class="header__menu-line"></span>
              <!-- <li class="header__menu-list">ВИДЕО</li>
              <span class="header__menu-line"></span> -->
              <a href="#contact">
                <li class="header__menu-list">{{ $t("contact") }}</li>
              </a>
              <span class="header__menu-line"></span>
            </ul>

            <!-- Header lang -->
            <ul class="header__menu col">
              <li
                v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'"
                class="header__menu-list header__menu-list_sm"
                @click="setLang('en')"
              >
                EN
              </li>
              <span
                v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'"
                class="header__menu-line header__menu-line_sm mx-2"
              ></span>
              <li
                v-if="$i18n.locale === 'en' || $i18n.locale === 'ru'"
                class="header__menu-list header__menu-list_sm"
                @click="setLang('uz')"
              >
                UZ
              </li>
              <span
                v-if="$i18n.locale === 'en'"
                class="header__menu-line header__menu-line_sm mx-2"
              ></span>
              <li
                v-if="$i18n.locale === 'uz' || $i18n.locale === 'en'"
                class="header__menu-list header__menu-list_sm"
                @click="setLang('ru')"
              >
                RU
              </li>
            </ul>
            <!-- <div class="menu-btn" :class="open ? 'open' : ''" @click="isOpen">
              <div class="menu-btn__burger"></div>
            </div> -->
            <button
              @click="isOpen"
              class="menu-last"
              onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))"
              aria-label="Main Menu"
            >
              <svg width="100" height="100" viewBox="0 0 100 100">
                <path
                  class="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path class="line line2" d="M 20,50 H 80" />
                <path
                  class="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>

    <header class="header-rdw" :class="{ 'to-rigth': open }">
      <ul class="header-rdw__item">
        <li class="header-rdw__item-list" @click="open = false">
          {{ $t("home") }}
        </li>
        <li
          class="header-rdw__item-list"
          @click="$router.push('/b-fresh'), !open"
        >
          BE FRESH
        </li>
        <a @click="open = false" href="#product">
          <li class="header-rdw__item-list">{{ $t("product") }}</li>
        </a>
        <a @click="open = false" href="#buy">
          <li class="header-rdw__item-list">{{ $t("store") }}</li>
        </a>
        <!-- <li class="header-rdw__item-list">ВИДЕО</li> -->
        <a @click="open = false" href="#contact">
          <li class="header-rdw__item-list">{{ $t("contact") }}</li>
        </a>
        <ul class="header-rwd__lang">
          <li
            v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'"
            class="header-rwd__lang-item"
            @click="setLang('en')"
          >
            EN
          </li>
          <span
            v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'"
            class="header-rwd__lang-line"
          ></span>
          <li
            v-if="$i18n.locale === 'en' || $i18n.locale === 'ru'"
            class="header-rwd__lang-item"
            @click="setLang('uz')"
          >
            UZ
          </li>
          <span
            v-if="$i18n.locale === 'en'"
            class="header-rwd__lang-line"
          ></span>
          <li
            v-if="$i18n.locale === 'uz' || $i18n.locale === 'en'"
            class="header-rwd__lang-item"
            @click="setLang('ru')"
          >
            RU
          </li>
        </ul>
      </ul>
    </header>

    <main class="main main_bordered" :class="open ? 'transition' : ''">
      <!-- First section -->
      <section class="row first flex-sm-row flex-column">
        <!-- First column -->
        <div class="col-sm-6 col-12">
          <div class="first__text">
            <h3 class="powerday">{{ $t("section2.title1") }}</h3>
            <h2 class="title">{{ $t("section1.title2") }}</h2>
            <p class="paragraph">{{ $t("section2.title3") }}</p>
            <p class="description">{{ $t("section2.title4") }}</p>
          </div>
        </div>
        <!-- Second column -->
        <div class="col-sm-6 col-12 first__rdw-images">
          <div class="first__images">
            <div class="first__images-run">
              <img
                src="../assets/ou7-img/front-1.png"
                alt="Run man animation image"
              />
            </div>
            <div class="first__images-bg">
              <img
                src="../assets/ou7-img/bg-1.png"
                alt="Background line animation image"
              />
            </div>
            <!--<div class="first__images-run">-->
            <!--              <img src="../assets/ou7-img/run.png" alt="Run man animation image">-->
            <!--            </div>-->
            <!--            <div class="first__images-bg">-->
            <!--              <img src="../assets/ou7-img/run-bg.png" alt="Background line animation image">-->
            <!--            </div>-->
            <!--            <div class="first__images-stone">-->
            <!--              <img src="../assets/ou7-img/stone-main.png" alt="Main stone animation image">-->
            <!--            </div>-->
            <!--            <div class="first__images-stone-1">-->
            <!--              <img src="../assets/ou7-img/stone-1.png" alt="Small animation stone">-->
            <!--            </div>-->
            <!--            <div class="first__images-stone-2">-->
            <!--              <img src="../assets/ou7-img/stone-1.png" alt="Small animation stone">-->
            <!--            </div>-->
            <!--            <div class="first__images-stone-3">-->
            <!--              <img src="../assets/ou7-img/stone-1.png" alt="Small animation stone">-->
            <!--            </div>-->
          </div>
        </div>
      </section>

      <!-- Second section -->
      <section class="row second flex-sm-row flex-column-reverse">
        <!-- First column -->
        <div class="col-sm-6 col-12 second__rwd-images">
          <div class="second__images">
            <div class="second__images-fighter">
              <img src="../assets/ou7-img/front-2.png" alt="Fighter image" />
            </div>
            <div class="second__images-fighter-bg">
              <img src="../assets/ou7-img/bg-2.png" alt="Background image" />
            </div>
          </div>
        </div>
        <!--        <div class="col-sm-6 col-12 second__rwd-images">-->
        <!--          <div class="second__images">-->
        <!--            <div class="second__images-fighter">-->
        <!--              <img src="../assets/ou7-img/fighter.png" alt="Fighter image">-->
        <!--            </div>-->
        <!--            <div class="second__images-fighter-bg">-->
        <!--              <img src="../assets/ou7-img/fighter-bg.png" alt="Background image">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- Second column -->
        <div class="col-sm-6 col-12">
          <div class="second__text">
            <h3 class="powerday" style="margin-bottom: 4px">
              {{ $t("section1.title1") }}
            </h3>
            <span v-if="$i18n.locale === 'en' || $i18n.locale === 'ru'">
              <h2 class="title" style="margin-bottom: 36px">
                <span style="position: absolute; top: 20px">
                  {{ $t("section2.title2") }}
                </span>
                <img
                  style="
                    width: 100px;
                    position: absolute;
                    left: 100px;
                    top: -5px;
                    margin-bottom: 1px;
                  "
                  src="../assets/ou7-img/number-one.png"
                  alt="Number one"
                />
              </h2>
            </span>
            <span v-if="$i18n.locale === 'uz'">
              <h2 class="title" style="margin-bottom: 36px">
                <img
                  style="
                    width: 100px;
                    position: absolute;
                    left: 10px;
                    top: -5px;
                    margin-bottom: 1px;
                  "
                  src="../assets/ou7-img/number-one.png"
                  alt="Number one"
                />
                <span style="position: absolute; left: 120px; top: 20px">{{
                  $t("section2.title2")
                }}</span>
              </h2>
            </span>
            <p class="paragraph" style="margin-top: 45px">
              {{ $t("section1.title3") }}
            </p>
            <p class="description">{{ $t("section1.title4") }}</p>
          </div>
        </div>
      </section>

      <!-- Third section -->
      <section class="row third">
        <!-- First colimn -->
        <div class="col-sm-6 col-12">
          <div class="third__text">
            <h3 class="powerday">{{ $t("section3.title1") }}</h3>
            <h2 class="title">{{ $t("section3.title2") }}</h2>
            <p class="paragraph">{{ $t("section3.title3") }}</p>
            <!--            <p class="description">{{ $t('section2.title4') }}</p>-->
          </div>
        </div>
        <!-- Second column -->
        <div class="col-sm-6 col-12 third__rwd-images">
          <div class="third__images">
            <div class="third__images-bodybuilder">
              <img
                src="../assets/ou7-img/front-3.png"
                alt="Bodybuilder image"
              />
            </div>
            <div class="third__images-bodybuilder-bg">
              <img
                src="../assets/ou7-img/bg-3.png"
                alt="Bodybuilder background image"
              />
            </div>
          </div>
        </div>
        <!--        <div class="col-sm-6 col-12 third__rwd-images">-->
        <!--          <div class="third__images">-->
        <!--            <div class="third__images-bodybuilder">-->
        <!--              <img src="../assets/ou7-img/bodybuilder.png" alt="Bodybuilder image">-->
        <!--            </div>-->
        <!--            <div class="third__images-bodybuilder-bg">-->
        <!--              <img src="../assets/ou7-img/bodybuilder-bg.png" alt="Bodybuilder background image">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </section>
    </main>

    <main class="main" :class="open ? 'd-none' : ''">
      <!-- fifth section -->
      <section id="product" class="row fourth align-items-center">
        <!-- first column -->
        <div class="col-sm-6 col-12">
          <div class="fourth__slider">
            <div class="fourth__slider-img-circle">
              <img
                class="circle-img"
                src="../assets/ou7-img/circle.png"
                alt="Circle image"
              />
              <img
                class="deg-360"
                src="../assets/ou7-img/deg-360.png"
                alt="360-deg image"
              />
              <img
                class="right-arrow"
                @click="$refs.d3slider.next()"
                style="cursor: pointer"
                src="../assets/ou7-img/right-arrow.png"
                alt="Right arrow"
              />
              <img
                class="left-arrow"
                @click="$refs.d3slider.prev()"
                style="cursor: pointer"
                src="../assets/ou7-img/left-arrow.png"
                alt="Left arrow"
              />
              <VueSlickCarousel ref="d3slider" v-bind="settings">
                <div class="tree5003d">
                  <div id="treesixty500"></div>
                </div>
                <div class="tree3303d">
                  <div id="treesixty330"></div>
                </div>
              </VueSlickCarousel>
              <div class="bg-shadow"></div>
            </div>
          </div>
        </div>
        <!-- second column -->
        <div class="col-sm-6 col-12">
          <div class="fourth__text">
            <h3 class="fourth__title">
              {{ $t("section4.title1") }}<br />{{ $t("section4.title2") }}
            </h3>
            <p class="paragraph">{{ $t("section4.title3") }}</p>
            <div class="fourth__jar">
              <div class="fourth__jar-big" @click="$refs.d3slider.prev()">
                <div class="big-jar">
                  <img
                    src="../assets/ou7-img/big-jar.png"
                    alt="Big energy icon"
                  />
                </div>
                <span>450 {{ $t("litr") }}</span>
              </div>
              <div class="fourth__jar-small" @click="$refs.d3slider.next()">
                <div class="small-jar">
                  <img
                    src="../assets/ou7-img/small-jar.png"
                    alt="Small energy icon"
                  />
                </div>
                <span>330 {{ $t("litr") }}</span>
              </div>
              <div class="energy-bg">
                <img
                  src="../assets/ou7-img/energy-bg.png"
                  alt="Energy background image"
                />
              </div>
            </div>
            <a href="#contact">
              <button class="buy-btn">{{ $t("buy") }}</button>
            </a>
          </div>
        </div>
      </section>

      <!-- What section -->

      <section class="what">
        <div class="what__item">
          <h3 class="what__item-title">{{ $t("section5.title1") }}</h3>
          <div @click="arrow = !arrow" class="what__item-img">
            <img
              v-if="!arrow"
              src="../assets/ou7-img/arrow-to-bottom.png"
              alt="Arrow to bottom"
            />
            <img
              v-else
              src="../assets/ou7-img/arrow-to-right.png"
              alt="Arrow to right"
            />
          </div>
        </div>
        <transition name="slide">
          <div v-if="!arrow" class="what__card">
            <VueSlickCarousel v-bind="settingsCenter">
              <div class="what__card-item">
                <img src="../assets/ou7-img/run-card.png" alt="Card img" />
                <p>{{ $t("section5.title2") }}</p>
              </div>
              <div class="what__card-item">
                <img src="../assets/ou7-img/b-card.png" alt="Card img" />
                <p>{{ $t("section5.title3") }}</p>
              </div>
              <div class="what__card-item">
                <img src="../assets/ou7-img/card-3.png" alt="Card img" />
                <p>{{ $t("section5.title4") }}</p>
              </div>
            </VueSlickCarousel>
          </div>
        </transition>
      </section>

      <!-- Marquee section -->
      <marquee class="marquee" behavior="scroll" direction="left">
        <div class="marquee__card">
          <img src="../assets/ou7-img/judo.png" />
          <img src="../assets/ou7-img/fitnes.png" />
          <img src="../assets/ou7-img/mma.png" />
          <img src="../assets/ou7-img/uzbekistan.png" />
        </div>
      </marquee>
    </main>

    <!-- Energy Drink section -->
    <div class="energy-drink" :class="open ? 'd-none' : ''">
      <h2 class="energy-drink__main-title">{{ $t("section6.title1") }}</h2>
      <div class="row d-flex align-items-center">
        <div class="col-sm-4 col-12 order-sm-1 order-1 my-sm-0 my-3">
          <h4 class="energy-drink__title">{{ $t("section6.title2") }}</h4>
          <p class="paragraph" style="width: 100%">
            {{ $t("section6.title3") }}
          </p>
        </div>
        <div class="col-sm-4 col-12 order-sm-2 order-0 my-sm-0 my-3">
          <div class="energy-drink_can-image">
            <img src="../assets/ou7-img/jar.png" alt="Can Image" />
          </div>
        </div>
        <div class="col-sm-4 col-12 order-sm-3 order-2 my-sm-0 my-3">
          <h4 class="energy-drink__title">
            {{ $t("section6.title4") }}<br />{{ $t("section6.title5") }}
          </h4>
          <p class="paragraph" style="width: 100%">
            {{ $t("section6.title6") }}
          </p>
        </div>
      </div>
    </div>

    <main class="main" :class="open ? 'd-none' : ''">
      <!-- Partners section -->
      <section id="buy" class="partners row">
        <div class="partners-slider">
          <VueSlickCarousel v-bind="settingsLast">
            <div class="partners-slider__card">
              <img class="center-image" src="../assets/ou7-img/makro.png" />
            </div>
            <div class="partners-slider__card">
              <img class="center-image" src="../assets/ou7-img/carrefour.png" />
            </div>
            <div class="partners-slider__card">
              <img class="center-image" src="../assets/ou7-img/korzinka.png" />
            </div>
            <div class="partners-slider__card">
              <img class="center-image" src="../assets/ou7-img/havas.png" />
            </div>
            <div class="partners-slider__card">
              <img class="center-image" src="../assets/ou7-img/asiauz.png" />
            </div>
            <div class="partners-slider__card">
              <img class="center-image" src="../assets/ou7-img/magnum.jpg" />
            </div>
          </VueSlickCarousel>
        </div>
      </section>
    </main>

    <!-- Footer -->
    <footer class="footer" :class="open ? 'd-none' : ''">
      <div id="contact" class="row">
        <div class="footer__item col-lg-12">
          <h4 class="contact">{{ $t("section6.title7") }}</h4>
          <p class="paragraph" style="width: 100%">
            {{ $t("section6.title8") }}
          </p>
          <div class="social-media">
            <div>
              <a href="https://ou7energy.com"
                ><img src="../assets/ou7-img/facebook.png" alt="Logo Facebook"
              /></a>
            </div>
            <div>
              <a href="https://ou7energy.com"
                ><img
                  src="../assets/ou7-img/instagram.png"
                  alt="Logo Instagram"
              /></a>
            </div>
            <div>
              <a href="https://ou7energy.com"
                ><img src="../assets/ou7-img/telegram.png" alt="Logo Telegram"
              /></a>
            </div>
          </div>
          <p style="width: 100%" class="paragraph">{{ $t("footer.phone") }}</p>
          <br />
          <span>{{ $t("footer.copyright") }}</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import viewer from "../components/360viewer";
import ThreeSixty from "@mladenilic/threesixty.js";
export default {
  components: {
    VueSlickCarousel,
    viewer,
    // treesixty
  },
  data() {
    return {
      open: false,
      arrow: false,
      secondSlider: false,
      images: {
        img500: [],
        img330: [],
      },
      settings: {
        infinite: false,
        swipeToSlide: false,
        swipe: false,
        slidesToShow: 1,
        arrows: false,
      },
      settingsCenter: {
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 3,
        variableWidth: true,
        initialSlide: 0,
        centerPadding: "25px",
        arrows: true,
        responsive: [
          {
            breakpoint: 1320,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 970,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 670,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 370,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "0px",
            },
          },
        ],
      },
      settingsLast: {
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 3,
        variableWidth: true,
        initialSlide: 0,
        centerPadding: "25px",
        arrows: true,
        responsive: [
          {
            breakpoint: 1420,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 970,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 670,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 370,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "0px",
            },
          },
        ],
      },
    };
  },
  methods: {
    next() {
      if (!this.secondSlider) {
        this.$refs.ml500.destroy();
      } else {
        this.$refs.classic.destroy();
      }
      setTimeout(() => {
        this.secondSlider = !this.secondSlider;
      }, 100);
    },
    isOpen() {
      this.open = !this.open;
    },
    setLang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
  },
  mounted() {
    let _runMan = document.querySelector(".first__images-run");
    let _fighter = document.querySelector(".second__images-fighter");
    let _bodyBuilder = document.querySelector(".third__images-bodybuilder");
    window.addEventListener("scroll", () => {
      const _scroll = Math.floor(window.scrollY);
      _runMan.style.transform = `translateY(-${_scroll / 2}px)`;
      _fighter.style.transform = `translateY(-${(_scroll - 700) / 2}px)`;
      _bodyBuilder.style.transform = `translateY(-${
        ((_scroll - 1500) * 2) / 3
      }px)`;
    });
    for (let i = 100; i < 173; i++) {
      this.images.img500.push(`${process.env.BASE_URL}rotate/ou7-450/${i}.png`);
    }
    for (let i = 97; i < 170; i++) {
      this.images.img330.push(`${process.env.BASE_URL}rotate/ou7-330/${i}.png`);
    }
    new ThreeSixty(document.getElementById("treesixty500"), {
      image: this.images.img500,
      count: 72,
      width: 190,
      height: 500,
      draggable: true,
      dragTolerance: 1,
      keys: true,
      inverted: true,
    });
    new ThreeSixty(document.getElementById("treesixty330"), {
      image: this.images.img330,
      count: 72,
      width: 190,
      height: 500,
      draggable: true,
      dragTolerance: 1,
      keys: true,
      inverted: true,
    });
  },
};
</script>

<style lang="scss">
#treesixty500,
#treesixty330 {
  cursor: pointer;
}
.tree5003d,
.tree3303d {
  position: relative;
  z-index: 99999 !important;
}
.slick-slide {
  opacity: 1 !important;
}
.test {
  .menu-last {
    width: 60px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: none;
    padding: 0;
    svg {
      width: 100%;
    }
  }
  .line {
    fill: none;
    stroke: #a0750e;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  @media screen and (max-width: 850px) {
    .menu-last {
      display: flex;
      outline: none;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(1, 1, 0.57, 0.58);
  -webkit-transition-timing-function: cubic-bezier(1, 1, 0.57, 0.58);
  -o-transition-timing-function: cubic-bezier(1, 1, 0.57, 0.58);
  transition-timing-function: cubic-bezier(1, 1, 0.57, 0.58);
}
.slide-enter-to,
.slide-leave {
  max-height: 300px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
