<template>
<!--  <div class="deg-360">-->
<!--    <VueSlickCarousel v-bind="settings">-->
      <vue-three-sixty
        :amount="72"
        imagePath="./rotate/ml"
        fileName="00{index}.png"
        disableZoom
      />
<!--    </VueSlickCarousel>-->
    <!-- <div class="circle-bg">
      <img :src="$img + 'circle-bg.svg'">
    </div>
    <a href="#buy">
      <button class="btn btn_second">КУПИТЬ</button>
    </a> -->
<!--  </div>-->
</template>

<script>
// import VueThreeSixty from 'vue-360'
import viewer2D from "./viewer";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    VueSlickCarousel,
    viewer2D,
  },
  props: {
    path: {
      type: String,
      default: "",
    },
    file: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      settings: {
        arrows: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        // "touchThreshold": 5,
      },
    };
  },
};
</script>

<style lang="scss">

#v360-menu-btns {
  display: none !important;
}
.v360-viewport {
  width: 190px !important;
  height: 500px !important;
  background: transparent !important;
}
.btn_second {
  display: none !important;
}
@media screen and (max-width: 1100px) {
  .buy-energy_responsive {
    .buy-energy__item {
      transform: translate(-100px, 0);
    }
  }
  .b-btn {
    margin-top: 60px;
    /* margin-left: 100px; */
    /* transform: translate(-100px, 0); */
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #ffffff;
    text-align: center;
    background: linear-gradient(180deg, #ffeaaa 13.33%, #d1a20d 77.4%);
    border-radius: 10px;
    width: 250px;
    padding: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    display: block;
  }
}
@media screen and (max-width: 750px) {
  .buy-energy_responsive {
    .buy-energy__item {
      width: 100% !important;
    }
  }
}

.circle-bg {
  position: absolute;
  left: -22% !important;
}
</style>
